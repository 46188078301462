<template>
<div>
    <!-- 通道管理》黑模板》黑模板 增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>增加黑模板</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="100px" style="margin-bottom: 12px;">
                    <el-form-item label="作用类型">
                        <el-select v-model="form_data.opt_type" size="mini" style="width:95px"> 
                            <el-option label="通道公司" value="1"></el-option>
                            <el-option label="通道" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="通道" v-show="form_data.opt_type == '1' || form_data.opt_type == '2'">
                        <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%"
                        remote
                        reserve-keyword
                        :remote-method="getChannelDataList"
                        > 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="模板">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5}" v-model="form_data.content" 
                                placeholder="变量使用#隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:0,//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取通道列表数据-请求接口获取
                    //this.getChannelDataList();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channelDataList:[],//通道列表数据-请求接口获取


            form_data:{
                opt_type:"1",//作用类型  1:通道公司 2:通道
                channel_id:"",//通道id
                content:"",//模板内容 变量使用#隔开
            },
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },


        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.channel_id)){
                this.$message.error("请选择通道");
                return;
            }
            if(API.isEmtry(this.form_data.content)){
                this.$message.error("请填写模板内容");
                return;
            }
            
            API.ChannelServlet({
                param:'addChannelBlackTemplate',
                opt_type:this.form_data.opt_type,
                channel_id:this.form_data.channel_id,
                content:this.form_data.content,
            }).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(search){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:search,//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>